<template>
  <div id="p_header_container">
    <div id="p_header">
      <router-link to="/">
        <img id="p_img" alt="logo" src="@/assets/logo.png" />
      </router-link>
      <NavItem
        v-for="(item, index) in items"
        :toPath="item.path"
        :title="item.title"
        :key="`item_${index}`"
      />
    </div>
    <hr />
  </div>
</template>

<script>
import NavItem from "./NavItem.vue";

export default {
  name: "Nav",
  components: {
    NavItem,
  },
  props: {
    list: Array,
  },
  methods: {},
  data() {
    return {
      moveIndex: 1,
      moveColor: "#666",
      items: [
        {
          path: "/guinong",
          title: "归农集团",
        },
        {
          path: "/brand",
          title: "品牌故事",
        },
        {
          path: "/base",
          title: "万亩基地",
        },
        {
          path: "/aloe",
          title: "芦荟护肤",
        },
        {
          path: "/prodev",
          title: "生产研发",
        },
        {
          path: "/joinus",
          title: "加入万非",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#p_header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#p_header {
  height: 89px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 960px;
  background-color: white;
}

#p_img {
  height: 39px;
  width: 31px;
  margin-right: 30px;
}

hr {
  margin: 0;
  width: 100%;
  border: 0;
  border-top: 1px solid #99999933;
}
</style>