
export default {
  name: "Footer",
  data() {
    return {
      copyright: "Copyright © 2020-2021 蕙芷农业 版权所有",
      address: "沪ICP备2021006767号",
      tips: "扫描左方二维码关注我们",
    };
  },
};
